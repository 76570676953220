import React, { Component } from 'react';
import {
  defaultTheme,
  Menu,
  MenuItem,
  MenuCheckbox,
  MenuHeader,
} from 'airbusutm-tools-ui/components';
import { LayersIcon } from 'airbusutm-tools-ui/icons';
import { withTheme } from 'styled-components';
import { coordEach } from '@turf/meta';
import { point } from '@turf/helpers';
import distance from '@turf/distance';
import queryString from 'query-string';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './styles.css';

// @TODO upgrade to @turf/centroid when v7 comes out.
// Due to bug in @turf/centroid we use this fixed version here.
// @turf/centroid version 7 has the fix but it is not released.
// Refer to LF-786 for details
function centroid(geojson) {
  let xSum = 0;
  let ySum = 0;
  let len = 0;
  coordEach(
    geojson,
    (coord) => {
      xSum += coord[0];
      ySum += coord[1];
      len += 1;
    },
    true
  );
  return point([xSum / len, ySum / len]);
}

let flightAreaCount = 0;
class App extends Component {
  state = {
    visibleLayers: [],
    amapLoaded: false,
  };
  constructor(props) {
    super(props);
    window.addEventListener('resize', () => {
      document.getElementById('the-map').style.height = `${window.innerHeight}px`;
    });
  }

  componentDidMount() {
    this.initMap();
  }

  initMap(props = {}) {
    // The intention is to support all types of environments:
    // - local dev testing with `yarn start`
    // - local container testing
    // - deployed container

    // http is needed for local testing
    const insecureHttp =
      window.location.protocol === 'http:' && window.location.hostname === 'localhost';

    // Expecting ports 80, 8000 and 3000
    const port = window.location.port === '3000' ? 8000 : window.location.port;
    const apiHost =
      insecureHttp && window.location.hostname === 'localhost'
        ? `localhost:${port}`
        : window.location.hostname;

    const queryParams = queryString.parse(window.location.search);
    const coords = queryParams.flight_polygon
      ? queryParams.flight_polygon.split('|').map((c) => c.split(',').map((x) => parseFloat(x)))
      : [];
    let center;
    if (coords && coords.length >= 3) {
      center = coords[0];
    } else if (queryParams.lnglat) {
      center = queryParams.lnglat.split(',').slice(0, 2).map((x) => parseFloat(x));
    } else {
      center = [-122.41942, 37.77493];
    };

    const zoom = queryParams.zoom ? parseFloat(queryParams.zoom) : 11;

    window.AMapLoader({
      appId: 'ac00088ad17eb648549d8bd3094e226ea852faaa',
      apiHost,
      insecureHttp,
      center,
      zoom,
      container: 'the-map',
      sidebarEnabled: true,
      defaultFitBoundOptions: {
        padding: {
          top: 42,
        },
      },
      sidebarOptions: {
        desktopPadding: {
          top: 42,
        },
        mobilePadding: {
          top: 42,
        },
      },
      onLoad: ((amap) => {
        this.amap = amap;
        window.mapHelper = {
          updateTfrLayers: this.amap.updateTfrLayers.bind(this.amap),
          reinitialize: this.onReinitializeMap.bind(this),
          viewGeojson: this.viewGeojson.bind(this),
        };
        const geocoder = new MapboxGeocoder({
          accessToken: amap.constructor.mapboxgl.accessToken,
          mapboxgl: amap.constructor.mapboxgl,
          marker: false,
        });
        amap.map.addControl(geocoder, 'top-left');
        if (queryParams.location) {
          geocoder.query(queryParams.location);
        }
        if (coords && coords.length >= 3) {
          amap
            .addFlightPolygon('flightarea', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    properties: {
                      name: 'Flight Area',
                      description: 'The flight area for this operation.',
                      detail: '',
                    },
                    geometry: {
                      type: 'Polygon',
                      coordinates: [coords],
                    },
                  },
                ],
              },
            })
            .fitBoundsToFlight();
        }
        if ('satellite' in queryParams) {
          this.setLayerVisiblity('satellite', true);
        }
        this.setState({
          amapLoaded: true,
        });
      }).bind(this),
      ...props,
    });
  }

  onReinitializeMap() {
    const props = {
      zoom: this.amap.map.getZoom(),
      center: this.amap.map.getCenter(),
    };
    if (this.amap) {
      this.amap.remove();
      this.amap = null;
    }
    this.initMap(props);
  }

  viewGeojson(geojson) {
    let features = [];
    if (Array.isArray(geojson)) {
      features = geojson;
    } else if (geojson.type === 'FeatureCollection') {
      features = geojson.features;
    } else {
      features = [geojson];
    }
    // Converts array of geometries to features
    if (features[0].type !== 'Feature') {
      features = features.map((geometry) => ({
        type: 'Feature',
        properties: {},
        geometry,
      }));
    }

    features.forEach((feature) => {
      const bounds = this.amap.constructor.featuresToBounds(feature);
      const [sw, ne] = bounds;
      const [nw, se] = [
        [ne[0], sw[1]],
        [sw[0], ne[1]],
      ];
      console.log({
        bounds,
        degreesWidth: distance(point(se), point(sw), { units: 'degrees' }),
        degreesHeight: distance(point(sw), point(nw), { units: 'degrees' }),
        metersWidth: distance(point(se), point(sw), { units: 'kilometers' }) * 1000,
        metersHeight: distance(point(sw), point(nw), { units: 'kilometers' }) * 1000,
        centroid: centroid(feature).geometry.coordinates,
      });
    });

    this.amap
      .addFlightPolygon(`flightarea${flightAreaCount++}`, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      })
      .fitBoundsToFlight();
  }

  handleLayerChange = (event) => {
    const { name: layerId, checked } = event.currentTarget;
    this.setLayerVisiblity(layerId, checked);
  };

  setLayerVisiblity(layerId, isVisible) {
    if (isVisible) {
      this.amap.show(layerId);
    } else {
      this.amap.hide(layerId);
    }

    const { visibleLayers: currentVisibleLayers } = this.state;
    let visibleLayers = currentVisibleLayers;

    // deselect existing weather layer if exists
    if (/^weather_/.test(layerId)) {
      visibleLayers = currentVisibleLayers.filter((id) => !/^weather_/.test(id));
    }

    this.setState({
      visibleLayers: isVisible
        ? [...visibleLayers, layerId]
        : visibleLayers.filter((id) => id !== layerId),
    });
  }

  render() {
    const { visibleLayers, amapLoaded } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        <div
          id="the-map"
          style={{
            width: '100vw',
            height: window.innerHeight,
          }}
        />
        <div
          style={{
            fontFamily: 'HelveticaNeueLight',
            position: 'absolute',
            top: 0,
            left: 0,
            fontSize: 32,
            height: 40,
            backgroundColor: 'rgba(255, 255, 255, .7)',
            width: '100vw',
            borderBottom: 'solid 1px #ccc',
          }}
        >
          <span style={{ paddingLeft: 10 }}>Airbus UTM</span>
        </div>
        <div style={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }}>
          <Menu icon={<LayersIcon color={defaultTheme.uiColor.secondary} />}>
            <MenuHeader>IMAGERY</MenuHeader>
            <MenuItem>
              <MenuCheckbox
                name="satellite"
                checked={visibleLayers.includes('satellite')}
                onChange={this.handleLayerChange}
              >
                SATELLITE
              </MenuCheckbox>
            </MenuItem>
            {amapLoaded && this.amap.weather && (
              <React.Fragment>
                <MenuHeader>WEATHER</MenuHeader>
                <MenuItem>
                  <MenuCheckbox
                    name="weather_radar"
                    checked={visibleLayers.includes('weather_radar')}
                    onChange={this.handleLayerChange}
                  >
                    RADAR
                  </MenuCheckbox>
                </MenuItem>
                <MenuItem>
                  <MenuCheckbox
                    name="weather_satellite"
                    checked={visibleLayers.includes('weather_satellite')}
                    onChange={this.handleLayerChange}
                  >
                    SATELLITE
                  </MenuCheckbox>
                </MenuItem>
                <MenuItem>
                  <MenuCheckbox
                    name="weather_wind_speed"
                    checked={visibleLayers.includes('weather_wind_speed')}
                    onChange={this.handleLayerChange}
                  >
                    WIND SPEED
                  </MenuCheckbox>
                </MenuItem>
              </React.Fragment>
            )}
          </Menu>
        </div>
      </div>
    );
  }
}

export default withTheme(App);
